@import "variables";

html, body, #root {
  height: 100%;
}

.switch-business-main-content {
  margin: auto;
  padding: auto auto auto auto; 
  margin-top: 10%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.switch-business-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
