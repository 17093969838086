html, body, #root {
  height: 100%;
}

main {
  background-color: white !important;
}

div.labels {
  margin-top: 10%;
  text-align: center;
  width: 100%;
}

div.labels h1 {
  display: inline-block;
  width: 33%;
}

div.centered-header {
  display: inline-block !important;
  text-align: center !important;
}

.uploadsContainer {
  height: 500px;
  margin-top: 50px;
}

.note {
  margin-top: 50px;
}

.center {
  text-align: center;
}

.overrides-header {
  width: 87%;
  padding: 50px 0 50px 0;
  margin: 1% auto auto;
}

div.error-message {
  white-space: pre;
}

.contentSignals {
  margin-top: 100px;
}

div.uploads {
  padding-left: 5px;
  margin-top: 2%;
  width: 100%;
  height: 500px;
}

div.upload {
  margin-left: 20%;
  margin-right: 20%;
  height: 100%;
  border: 2px solid black;
}

div.upload div {
  height: 100%;
  width: 100%;
  display: table;
  overflow: hidden;
  text-align: center;
  align-content: center;
}

div.upload div p{
  height: 100%;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.awsui .awsui-app-layout__content, .awsui .awsui-app-layout__container {
  overflow-x: hidden;
}

.awsui .awsui-alert-type-error {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 5%;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.awsui .awsui-alert-type-success {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 5%;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.awsui .awsui-alert-type-info {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 5%;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.awsui .awsui-alert-type-info .awsui-alert-icon,
.awsui .awsui-alert-type-success .awsui-alert-icon,
.awsui .awsui-alert-type-error .awsui-alert-icon {
  width: 5%;
}

button._action-button_1ih5m_75._button_1npim_7._variant-normal_1npim_27:not(#\9) {
  background: transparent;
  pointer-events: none;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: red;
}
