nav {
  li {
    line-height: 3.0;
  }
  
  li::selection {
    color: orange;
  }
  
  ul {
    padding-top: 30px;
    font-size: 25px;
  }
}

.awsui .awsui-side-navigation__header {
  text-align: center;
  pointer-events: none;
  padding-right: 3.5rem;
}

.website-navigation-top {
  background-color: #232f3e;
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  .awsui-row {
    margin: 0 !important;
  }
}

.navbar-link {
  cursor: pointer;
  background-color: #232f3e;
  &:hover {
    background-color: lighten($color: #232f3e, $amount: 7);
  }
}

.navbar-link-selected {
  color: #FF6600;
  font-weight: bold;
  background-color: lighten($color: #232f3e, $amount: 7);
}

.navbar-title-container {
  min-width: 400px;
  z-index: 1;
}

.navbar-title {
  font-size: 2.8rem;
  line-height: 5rem;
  float: left;
  padding-left: 20px;
  cursor: pointer;
  &:hover {
    background-color: lighten($color: #232f3e, $amount: 7);
  }
}

.navbar-subtitle {
   font-size: 1.5rem;
   line-height: 5rem;
   padding: 0 5px 0 5px;
   text-align: left;
   vertical-align: text-bottom;
   float:left;
   &:hover {
     background-color: lighten($color: #232f3e, $amount: 7);
   }
   cursor: pointer;
 }

.navbar-flowDropDown {
  font-size: 1.5rem;
  line-height: 5rem;
  padding: 0 5px 0 5px;
  text-align: left;
  vertical-align: text-bottom;
  float:left;
  &:hover {
    background-color: lighten($color: #232f3e, $amount: 7);
  }
  cursor: pointer;
}

.awsui .awsui-util-p-m, .navbar-flowDropDown button {
  white-space: nowrap !important;
}